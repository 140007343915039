exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admission-js": () => import("./../../../src/pages/admission.js" /* webpackChunkName: "component---src-pages-admission-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pictures-js": () => import("./../../../src/pages/pictures.js" /* webpackChunkName: "component---src-pages-pictures-js" */),
  "component---src-pages-purpose-js": () => import("./../../../src/pages/purpose.js" /* webpackChunkName: "component---src-pages-purpose-js" */)
}

